<template>
  <div>
    <EditorFrame v-if="hb" :hb="hb" :showPreview="false" :showTags="false">
      <div class="editor-wrapper">
        <EditorSider :activeIndex.sync="currentIndex" :len="len" :modifiable="false" />
        <TranslateEditor
          style="width: calc(100% - 5em);"
          :transList="translationList[currentIndex]"
        />
      </div>
      <template v-slot:buttons>
        <Button class="center-block-btn" type="primary" @click="submitFn">确认无误，提交</Button>
      </template>
      <template v-slot:right-side>
        <Tabs :value="'name1'">
          <TabPane label="版本记录" name="name1">
            <colophon :taskInfo="taskInfo"></colophon>
          </TabPane>
        </Tabs>
      </template>
    </EditorFrame>
  </div>
</template>

<script>
import lingYunApi from "../api/api";
import { getHbTaskView } from "../api/hbService";
import homeService from "../api/homePageService";
import editorMixin from "./editorMixin";

export default {
  components: {},
  data() {
    return {
      hb: {},
      len: 0,
      translationList: [],
      taskInfo: []
    };
  },
  methods: {
    initData() {
      let taskId = this.$route.query.taskId;
      let bizId = this.$route.query.bizId;
      let processId = this.$route.query.processId;
      let contentType = this.$route.query.contentType;
      let params = { taskId, processId, bizId, contentType };

      getHbTaskView(params).then(res => {
        this.hb = res.content;
        this.taskInfo = res.taskInfo;
        this.translationList = this.hb.audioMap;
        this.len = Object.keys(this.hb.audioMap).length;
      });
    },
    submitFn() {
      this.hb.taskName = 7;
      this.hb.pass = 0;
      lingYunApi
        .postSubmit(this.hb)
        .then(res => {
          this.$Message.success("提交成功");
          this.isDropTask = false;
          setTimeout(() => {
            this.$router.back();
          }, 1000);
        })
        .catch(err => {
          this.$Message.warning("提交失败," + (err.message || err));
        });
    }
  },
  beforeMount() {
    this.initData();
    this.currentIndex = 0;
  },
  mounted() {},
  mixins: [editorMixin]
};
</script>

<style scoped>
.editor-wrapper {
  padding: 1em 0;
  display: flex;
}
</style>